@import "./../../../../variables.scss";

.blength-section-container {
    margin-top: -5px;
    .label-selected {
        color: $info-main;
    }

    .radio-group {
        display: flex;
        flex-direction: row;
        padding-left: 11px;
        padding-bottom: 16px;

        .mdc-radio__background {
            color: $info-main;
        }

        .radio-group-container {
            display: flex;
            flex-direction: row;

            .MuiFormControlLabel-root:not(:first-child) {
                margin-left: 20px;
                margin-right: 0px;
            }
        }
    }
}
