@import "../../variables.scss";

.drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 22px;
    padding-bottom: 12px;

    i {
        color: $primary-main !important;
    }

    .corning-logo {
        img {
            width: 218px;
            height: 32px;
        }
    }
}
