.debug-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    width: 100%;

    .debug-menu-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
}
