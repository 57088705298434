@import "../../variables.scss";

.canvas-container {
    display: flex;
    flex-grow: 1;
    height: 100%;

    canvas {
        position: fixed;
    }
}

.pan-and-zoom-toolbar {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    pointer-events: none;

    .vertical-divider {
        width: 2px;
        height: 16px;
        background-color: $divider;
        border: none;
        margin: 0 4px;
    }

    .toolbar-group {
        display: flex;
        gap: 16px;
    }

    .toolbar-item {
        display: flex;
        background-color: $primary-12p;
        border-radius: $card-border-radius;
        align-items: center;
        pointer-events: all;
        height: 36px;

        &.zoom-control {
            .current-zoom {
                padding: 0 4px;
                min-width: 40px;
                text-align: center;
            }
        }

        &.resize-control {
            .menu-button {
                color: $text-secondary;
                min-width: 146px;
                justify-content: space-between;
            }
        }

        &.reference-note {
            padding: 0 8px;
        }
    }

    .fullscreen-button {
        pointer-events: all;
    }
}
