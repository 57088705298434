@import "../../../variables.scss";
@import "../../../styles.scss";

$card-width: 875px;
$icon-length: 36px;
$row-spacing: 0px;

.report-settings {
    .MuiDialog-paper {
        position: absolute;
        top: $dialog-top-position;
        right: 16px;
        max-width: unset;
        box-shadow: $elevation-4;
        margin: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0;
    }

    .report-content {
        display: flex;
        flex-direction: column;
        width: 512px;
        height: 625px;
    }

    .dialog-header {
        padding: 0;
    }

    .user-provided-images {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
        max-height: 180px;
        overflow: scroll;
    }
}

.file-upload-dialog {
    .MuiDialog-paper {
        max-width: unset;
        width: 600px;
        box-shadow: $elevation-4;

        .MuiPaper-rounded {
            background-color: $primary-8p;
        }
    }
}

.horizontal-container {
    display: flex;
    flex-direction: row;
}

.horizontal-field-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .radio-group {
        .MuiRadio-root {
            margin-left: 11px;
        }
    }
}

.print-container {
    margin-top: 10px !important;
    .field-radio {
        top: -10px !important;
    }
    .radio:first-child {
        margin-top: -50px !important;
    }

    .radio:last-child {
        margin-left: 55px !important;
    }
}

.checkbox, .checkbox-selected {
    display: flex;
    align-items: center;
    vertical-align: middle;

    &-disabled {
        .MuiTypography-root {
            color: $color-on-disabled !important;
        }
    }

    .MuiTypography-root {
        color: $color-on-surface;
    }
}

.section-divider {
    height: 1.2px;
    background: black 0% 0% no-repeat padding-box;
    opacity: 0.3;
    margin-top: 8px;
    margin-bottom: 8px;
}

.footer-panel {
    margin-top: 16px;

    .field-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .horizontal-field-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .split-fields {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 12px;
            
            * {
                flex-grow: 1;
            }
        }
    }
}

.drawing-panel {
    > * {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .label-selected {
        color: $info-main;
    }

    .hint-text {
        padding-left: 35px;
        letter-spacing: 0px;

        &--invalid {
            color: $error-main !important;
            padding-left: 35px;
            letter-spacing: 0px;
        }
    }

    .label-disabled {
        color: $color-on-disabled;
    }

    .report-section {
        display: flex;
        flex-direction: column;
        padding-top: 8px;

        div + .horizontal-field-container {
            label {
                margin-top: 3px;
            }
        }

        .section-options {
            display: flex;
            align-items: center;
            width: 100%;

            * {
                flex-grow: 1;
            }
        }

        .radio-group {
            display: flex;
            width: 100%;

            
            > .MuiFormGroup-root {
                width: 100%;
            }

            .radio-group-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                gap: 12px;
            }
        }
    }

    .setup-notes {
        margin-top: 16px;
    }
}