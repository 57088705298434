@import "../../styles.scss";

.trigger-management-dialog {
    @include card;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-x: hidden;
    width: 560px;

    .dialog-header {
        padding-bottom: 0;
    }

    .table-container {
        display: flex;
        flex-direction: column;
        margin: 0 16px;
        height: 100%;
        max-height: 360px;
        overflow: auto;

        .MuiTable-root {
            height: 100%;
            max-height: 320px;
        }

        .MuiTableCell-head {
            background-color: $background-paper2;
        }

        .MuiTableCell-root {
            height: 40px !important;
            padding: 0 16px !important;
        }

        .connector-cell {
            svg {
                margin-right: 32px;
                transform: rotate(-90deg);
            }
        }

        .color-button {
            cursor: pointer;
        }
    }

    .trigger-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 20px 16px;

        .MuiButton-root + .MuiButton-root {
            margin-left: 12px !important;
        }
    }

    .MuiFormControlLabel-root {
        margin-top: 16px;
        margin-left: 16px;
        user-select: none;

        .MuiCheckbox-root {
            &.Mui-checked {
                color: $info-main;
            }
        }

        &.Mui-disabled {
            .MuiTypography-root {
                color: $color-on-disabled;
            }
        }
    }
}
