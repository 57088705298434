@import "./variables.scss";

:root {
    --mdc-theme-primary: $primary-contrastText;
    --mdc-theme-secondary: $info-main;
    --mdc-theme-surface: $color-surface;
    --mdc-theme-background: $primary-4p;

    --mdc-theme-on-primary: $color-on-primary;
    --mdc-theme-on-secondary: $background-paper;
    --mdc-theme-on-surface: $color-on-surface;
    --mdc-theme-text-primary: $color-on-primary;
}

body {
    overflow: hidden;
    margin: 0;
}

html,
body,
#root {
    width: 100vw;
    height: 100vh;
}

.workspace {
    width: 100%;
    height: 100%;
    position: relative;
}

.title {
    text-transform: capitalize;
}

button {
    text-transform: uppercase !important;

    ::-moz-focus-inner {
        border: 0;
    }
    &:not(:disabled):focus {
        outline: none;
    }
}

.MuiDialogActions-root {
    padding: 16px 24px;
    .MuiButtonBase-root {
        &:nth-child(2) {
            margin-left: 8px !important;
        }
    }
}

.MuiDialog-paper {
    background-color: $background-paper2 !important;

    .MuiDialogTitle-root {
        color: rgba($text-primary, 0.8);
    }
}

.MuiList-root,
.MuiAutocomplete-listbox,
.MuiListItemText-root,
.MuiTooltip-popper,
.MuiFormControl-root {
    .MuiMenuItem-root,
    .MuiAutocomplete-option,
    .MuiTypography-root,
    .MuiFormLabel-root,
    .MuiTooltip-tooltip,
    .MuiInputBase-root {
        font-family: "Inter" !important;
    }
}

.MuiButtonBase-root {
    font-family: "Inter" !important;
}

.MuiOutlinedInput-root:hover {
    &.Mui-disabled {
        fieldset {
            border-color: $action-disabled !important;
        }
    }
}

.mdc-data-table__table {
	border-spacing: inherit;
}

.icon-button-border {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid $primary-main;
    height: 34px;
    width: 34px;
}