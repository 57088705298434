@import "../../variables.scss";

.drawer-sub-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 14px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 14px;

    .cable-label {
        margin-right: 10px;
    }

    .sort-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .sort-select {
            height: 40px;
            max-height: 40px;
            width: 181px;
            text-transform: capitalize;
        }

        .sort-button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 8px;
            border-radius: 4px;
            height: 36px;
            width: 36px;
        }
    }

    .MuiTextField-root {
        height: 100%;
        margin: auto;

        .MuiInputBase-root {
            background-color: $primary-8p;
            padding-right: 0 !important;

            .MuiSvgIcon-root {
                color: $primary-main;
            }

            .MuiInputBase-input {
                color: $primary-main;
                border: none;
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }
    }
}
