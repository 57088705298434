@import "../../styles.scss";
@import "@rmwc/data-table/data-table.css";

.connector-assignment-dialog {
    position: absolute;
    top: $dialog-top-position;
    right: 16px;
    left: unset;

    @include card;
    overflow-x: hidden;
    width: 762px;
    max-width: 762px;
    min-height: 780px;
    box-shadow: $elevation-4;

    .dialog-header {
        padding-bottom: 8px;
    }

    .horizontal-divider {
        height: 1px;
        margin: 0 16px;
        background-color: rgba($color-on-surface, 0.2);
    }

    .vertical-divider {
        width: 2px;
        margin: 0 12px;
        background-color: rgba($color-on-surface, 0.2);
    }

    .polarity-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 4px;
        padding-bottom: 8px;

        .select-all-connectors {
            margin-left: 2px;

            .MuiFormControlLabel-root {
                margin-left: 0px !important;
                margin-right: 0px !important;
            }
        }

        .actions-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            padding: 0 16px;

            .MuiSelect-select {
                text-transform: uppercase !important;
                padding-bottom: 6.5px;
                padding-top: 6.5px;
                min-width: 100px;
            }
        }
    }

    .assignment-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 16px;
        padding-right: 16px;

        .local-container {
            display: flex;
            flex-direction: column;

            &.source {
                width: 274px;
            }

            &.destination {
                width: 342px;
            }

            .container-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-bottom: 8px;
                height: 48px;
                background-color: rgba($color-on-surface, 0.1);

                .container-title {
                    text-transform: uppercase;
                }

                &.left-destination {
                    justify-content: flex-start;

                    .MuiButtonBase-root {
                        margin-left: 8px !important;
                    }

                    .container-title {
                        padding-left: 24px;
                    }

                    &.single-tap {
                        margin-right: 8px !important;
                    }
                }

                &.right-destination {
                    justify-content: flex-end;

                    .MuiButtonBase-root {
                        margin-right: 8px !important;
                    }

                    .container-title {
                        padding-right: 24px;
                    }
                }

                .material-icons {
                    color: $primary-main;
                }

                button {
                    &:disabled {
                        .material-icons {
                            color: rgba($primary-main, 0.4) !important;
                        }
                    }
                }
            }

            .assignment-table {
                height: 496px;
                background-color: $primary-8p;
                border: none;
                overflow: auto;

                .delete-icon {
                    visibility: hidden;
                }

                .edit-icon {
                    visibility: hidden;
                }

                &.source {
                    width: 274px;

                    .mdc-data-table__cell {
                        &.fiber-indicator {
                            padding-right: 0;
                        }
                    }
                }

                &.left-destination {
                    margin-right: 8px;
                }

                &.right-destination {
                    margin-left: 8px;
                }

                .mdc-data-table__row {
                    border: none;
                    width: 100%;

                    .tooltip {
                        visibility: hidden;
                    }

                    &:hover {
                        background-color: rgba($color-on-primary, 0.12);
                    }

                    &.selected {
                        background-color: rgba($primary-main, 0.2);

                        .delete-icon {
                            visibility: hidden;
                        }

                        .edit-icon {
                            visibility: hidden;
                        }

                        .mdc-data-table__cell {
                            &.assignment-icon {
                                .row-selection-icon {
                                    visibility: visible;
                                }
                            }
                        }
                    }

                    &:focus {
                        background-color: rgba($color-on-primary, 0.04);
                    }

                    &.assigned {
                        .propagation-icon {
                            visibility: visible;
                        }

                        &:hover {
                            .delete-icon {
                                visibility: visible;
                            }

                            .edit-icon {
                                visibility: visible;
                            }

                            .propagation-icon {
                                background: unset !important;
                            }

                            .mdc-data-table__cell {
                                &.delete-button {
                                    &.editDisabled {
                                        .material-icons {
                                            color: $color-on-disabled;
                                        }
                                    }

                                    .material-icons {
                                        color: $error-main;
                                    }
                                }

                                &.edit-button {
                                    .material-icons {
                                        color: $color-on-surface;
                                    }
                                }
                            }
                        }

                        .mdc-data-table__cell {
                            &.assignment-icon {
                                i {
                                    visibility: visible;
                                }
                            }
                        }
                    }

                    .mdc-data-table__cell {
                        height: 48px;
                        width: 100%;
                        padding: 0 16px;
                        align-items: center;

                        &.assignment-icon {
                            padding: 0 16px;
                            width: 56px;

                            i {
                                visibility: hidden;
                                vertical-align: middle;
                                color: $success-main;

                                &.disabled {
                                    color: $color-on-disabled;
                                }
                            }

                            .row-selection-icon {
                                visibility: hidden;
                            }
                        }

                        &.connector-info {
                            padding-left: 0;
                            padding-right: 8px;
                        }

                        &.delete-button {
                            padding-left: 0;
                            padding-right: 6px;
                        }

                        &.edit-button {
                            padding-left: 0;
                            padding-right: 16px;
                        }

                        &.propagation-cell {
                            padding: 0;
                            padding-right: 6px;
                        }

                        .material-icons {
                            color: $color-on-disabled;
                        }
                    }
                }
            }
        }
    }

    .reset-button-container {
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
    }

    .navigation-bar {
        position: absolute;
        bottom: 16px;
        width: 100%;

        .tool-item {
            margin: 0;
        }
    }
}

.connector-assignment-dialog-collapsed {
    position: absolute;
    top: $dialog-top-position;
    right: 16px;
    left: unset;
    width: unset;
    height: unset;
    @include card;

    .dialog-header {
        gap: 8px;
    }
}
