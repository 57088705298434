@import "../../variables.scss";
@import "../../styles.scss";

.units-panel {
    position: absolute;
    top: $dialog-top-position;
    right: 0px;
    width: 345px;
    margin-right: 1em;
    @include card;
    z-index: 2;
    border-radius: $card-border-radius;
    border: 1px;
    border-color: $background-paper2;
    border-style: solid;

    padding: 16px 16px 32px;

    .units-container {
        #unit-dropdown {
            padding-top: 16px;
        }
    }

    .units-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
    }
}
