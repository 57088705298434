@use "sass:math";
@import "../styles.scss";

#offscreen-container {
    position: fixed;
    left: 0px;
    top: 100%;

    table,
    td,
    th,
    thead,
    tr {
        border: 0.5px solid black;
        border-collapse: collapse;
        font-family: "Inter", Arial, Helvetica, sans-serif;
        text-align: center;
    }
}
