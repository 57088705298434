@import "../../variables.scss";

.assembly-type-edit {
    .MuiPaper-root {
        position: absolute;
        top: 80px;
        right: 16px;
        margin: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0;
    }

    .option-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dialog-header {
       gap: 8px;
    }
}
