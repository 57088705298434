@import "../../variables.scss";

.collapse-options {
    .MuiPaper-root {
        position: absolute;
        top: 80px;
        right: 16px;
        width: 300px;
        margin: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0;
    }
}
