@import "../../variables.scss";
@import "../../styles.scss";

$text-field-bg-color: #4e4e50;

.cable-info {
    .cable-part-number {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
    }

    .cable-name {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
    }

    .cable-desc {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 24px;
    }

    .cable-polarity {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
    }

    .info-buttons {
        padding-left: 16px;
        padding-right: 16px;

        .back-button {
            float: left;
        }

        .right-info-buttons {
            float: right;
        }
    }
}
