.mesh {
    .mesh-checkbox {
        padding-bottom: 16px !important;
        padding-left: 11px;
    }

    .mesh-color {
        width: 100%;

        .MuiInputBase-inputAdornedEnd {
            margin-right: 25px;
        }
    }
}
