@import "../../variables.scss";

.drawer-page-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: "Inter";
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;

    .title-label {
        font-size: 20px;
        font-weight: bold;
        color: $color-on-primary;
    }

    .part-number-label {
        font-size: 16px;
        color: $primary-main;
        height: 21px;
    }
}
