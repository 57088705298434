@import "../../../styles.scss";

.template {
    display: flex;
    height: 56px;
    user-select: none;

    &.selected {
        background-color: $primary-8p;
    }

    &:hover {
        background-color: $primary-4p;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6px 8px;
        width: 100%;

        .name {
            font: 16px "Inter";
        }
    }
}
