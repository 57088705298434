@import "../../styles.scss";

.color-dialog {
    @include card;
    overflow: hidden;
    width: 370px;
    max-width: 370px;
    box-shadow: unset !important;

    .dialog-header {
        padding: 20px 24px 0px 24px;
    }

    &.label-color {
        position: absolute;
        z-index: 1300;
        top: 120px;
        left: 400px;
        box-shadow: $elevation-4 !important;
    }

    &.connector-color {
        position: absolute;
        z-index: 1300;
        top: 120px;
        left: 200px;
        box-shadow: $elevation-4 !important;
    }

    &.trigger-management {
        position: fixed;
        top: 50% !important;
        left: 50% !important;
        transform: translate(80%, -50%);
        z-index: 1300;
        box-shadow: $elevation-4 !important;
    }

    &.rapid-bundle {
        position: absolute;
        bottom: 60px !important;
        right: -100px !important;
        box-shadow: $elevation-4 !important;
    }

    &.mesh {
        position: fixed;
        top: 45% !important;
        right: 420px !important;
        box-shadow: $elevation-4 !important;
        z-index: 1300;
    }

    &.main-mesh {
        position: fixed;
        top: 270px !important;
        right: 15px !important;
        z-index: 1300;
        box-shadow: $elevation-4 !important;
    }

    .dialog-header {
        padding-bottom: 8px;
    }

    .color-palette {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding-left: 20px;

        .color-button {
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 16px;
        }
    }

    .dialog-footer {
        margin-right: 24px;
        display: flex;
        justify-content: flex-end;

        .dialog-footer-button-container {
            gap: 8px;
        }
    }
}
