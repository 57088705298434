@import "./variables.scss";

$dialog-top-position: 80px;

select,
option {
    font-family: "Inter", Arial;
    font-weight: bold;
}

@mixin blur($amount, $background: null, $important: false) {
    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        @if $important {
            backdrop-filter: blur($amount) !important;
            -webkit-backdrop-filter: blur($amount) !important;
        } @else {
            backdrop-filter: blur($amount);
            -webkit-backdrop-filter: blur($amount);
        }
    }

    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        @if $background != null {
            $opaque: opacify($background, 1);
            @if $important {
                background: $opaque !important;
            } @else {
                background: $opaque;
            }
        }
    }
}

@mixin card {
    background: $background-paper2;
    border-radius: $card-border-radius;
    box-shadow: $elevation-4;
}

// Remove arrows/spinners in Firefox
input[type="number"] {
    -moz-appearance: textfield;
}
