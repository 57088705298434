@import "../../variables.scss";

.collapse-dialog {
    .dialog-header {
        padding: 16px 16px;
    }
}

.dialog-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 16px 0px;
    width: auto;

    .dialog-header-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .dialog-header-icon {
            padding-right: 8px;
        }

        .dialog-header-title {
            color: rgba($text-primary, 0.8);
        }
    }

    .dialog-header-button-container {
        display: flex;
        flex-direction: row;

        .material-icons {
            color: $primary-main !important;
        }
    }
}
