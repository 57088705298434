@import "../../../styles.scss";

.fiber-mapping-dialog {
    position: absolute;
    top: $dialog-top-position;
    right: 16px;
    @include card;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 880px;
    max-width: 880px;
    box-shadow: $elevation-4;

    .dialog-header {
        padding-bottom: 8px;
    }

    .horizontal-divider {
        height: 1px;
        margin: 0 16px;
        background-color: rgba($color-on-surface, 0.2);
    }

    .subheader-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px 0px;

        .polarity-type {
            color: $color-on-surface;
        }
    }

    .mapping-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 8px 16px 8px;
        padding: 16px 16px 0;
        height: 264px;
        background-color: $primary-8p;
        border-radius: 4px;
        user-select: none;

        .selection-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }

        .selection-dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;

            &.source {
                width: 170px !important;
            }

            &.tap {
                width: 120px !important;
            }

            .MuiSelect-select {
                padding: 8px;
            }
        }

        .connector-container {
            height: 100%;
        }

        .connector-pin {
            cursor: pointer;

            &:hover {
                .connector-pin-highlight {
                    fill: rgba($color-on-primary, 0.12);
                    fill-opacity: 1;
                }
            }

            &.disabled {
                cursor: unset;

                .connector-pin-highlight {
                    fill-opacity: 0;
                }
            }

            &.selected {
                .connector-pin-highlight {
                    fill: rgba($info-main, 0.2);
                    fill-opacity: 1;
                }
            }
        }
    }

    .fiber-mapping {
        &-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding: 0 16px;
            padding-bottom: 16px;
        }

        &-actions {
            gap: 8px;
            display: flex;
        }
    }
}

.fiber-mapping-dialog-collapsed {
    position: absolute;
    top: $dialog-top-position;
    right: 16px;

    @include card;

    .dialog-header {
        gap: 8px;
    }
}
