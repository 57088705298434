@import "../../variables.scss";

.settings {
    .MuiPaper-root {
        position: absolute;
        top: 80px;
        right: 16px;
        width: 385px;
        margin: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0;

        .drawing-specs {
            .MuiFormControlLabel-root {
                margin-left: -12px;
            }
        }
    }

    .MuiTypography-subtitle1 {
        font-weight: 500 !important;
    }

    .MuiBox-root {
        .MuiTypography-root {
            font-weight: 500 !important;
        }
    }

    .MuiFormGroup-root {
        width: 100%;
        row-gap: 0px;

        .MuiFormControlLabel-root {
            width: 50%;
        }
    }

    .measurements-position {
        margin-top: 4px;
    }
}
