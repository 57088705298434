@import "../../variables.scss";

.drawer-row {
    align-items: center;
    padding-top: 16px;
    padding-bottom: 9px;

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    &:hover {
        background: $primary-4p;

        .build-toolbar {
            opacity: 1;
        }

        .drag-button {
            opacity: 1;
        }
    }

    &.selected {
        background: $primary-8p;

        &:hover {
            background: $primary-4p;
        }

        .build-toolbar {
            opacity: 1;
        }

        .drag-button {
            opacity: 1;
        }
    }

    .row-middle {
        width: 55%;
    }

    .row-end {
        display: flex;
        flex-direction: column;
        text-align: right;
    }
}
