@import "../../variables.scss";

.dialog-footer {
    margin-left: 25px;
    margin-bottom: 10px;

    .dialog-footer-button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
}
