@import "../../styles.scss";

.assign-polarity-container {
    width: 100%;
    overflow: hidden;

    .polarity-button-container {
        height: 36px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;

        .polarity-button {
            padding-top: 0px;
            padding-bottom: 0px;
            margin-right: 16px;
            font-family: $font-family;

            &-selected {
                color: $primary-main;
                background-color: $primary-12p;
                font-family: $font-family;

                i {
                    font-size: 24px;
                    margin-right: 7px;
                    font-weight: 1000;
                }
            }

            &-applied {
                margin-right: 16px;
                color: $success-main;
                background-color: $success-12p;
                font-family: $font-family;

                i {
                    font-size: 24px;
                    margin-right: 7px;
                    font-weight: 1000;
                }
            }

            i {
                font-size: 24px;
                margin-right: 7px;
                font-weight: 1000;
            }
        }
    }

    .assign-polarity-content {
        display: flex;
        flex-direction: row;
        margin-top: 16px;

        .polarity-map-container {
            width: 425px;
            min-width: 425px;
            background-color: $primary-8p;
            border: none;
            border-radius: 0;

            .polarity-row {
                display: flex;
                flex-direction: row;

                .polarity-icon {
                    margin-right: 10px;
                    margin-left: 10px;

                    .material-icons {
                        transform: translate(0, 25%);
                    }
                }

                .polarity-description {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .polarity-name {
                        margin-bottom: 1px;
                        letter-spacing: 0px;
                        text-align: left;
                        font: normal normal bold 14px $font-family;
                        color: #4e4e50;
                        opacity: 1;
                    }

                    .polarity-revision {
                        text-align: left;
                        font: normal normal 400 12px $font-family;
                        letter-spacing: 0px;
                        color: #4e4e50;
                        opacity: 1;
                    }
                }
            }

            .mdc-data-table__row {
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-bottom-color: rgba(0, 0, 0, 0.12);
                cursor: pointer;

                .MuiIconButton-root {
                    visibility: hidden;
                }

                &.editable {
                    .MuiIconButton-root {
                        visibility: visible;
                    }
                }

                &:hover {
                    background-color: $primary-4p;
                }

                .mdc-data-table__cell {
                    width: 100%;
                    padding: 0;

                    .material-icons {
                        &.applied {
                            color: $success-main;
                        }
                    }
                }
            }
        }

        .assign-polarity-graphic-container {
            display: flex;
            flex-direction: column;
            background-color: white;
            width: 100%;
            height: 440px;
            border: 1px solid $outline-border;

            .polarity-graphic {
                background-color: white;
                display: flex;
                flex-direction: column;
                height: 100%;

                .assign-polarity-apply-container {
                    background-color: $primary-8p;
                    padding: 16px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .mdc-button--raised {
                        margin-top: -5px;
                    }
                }
            }

            .polarity-instructions {
                color: rgba($color-on-surface, 0.8);
                padding: 24px;

                .polarity-important {
                    display: flex;
                    flex-direction: row;
                }
            }

            .polarity-unavailable {
                display: flex;
                flex-direction: row;
                padding: 24px;

                i {
                    font-size: 32px;
                }

                .polarity-unavailable-text {
                    margin-left: 16px;
                }
            }

            .assign-polarity-graphic-item + .assign-polarity-graphic-item {
                margin-top: 8px;
            }
        }
    }
}
