// Text
$text-primary: #000000de;
$text-secondary: rgba($text-primary, 0.6);
$text-disabled: rgba($text-primary, 0.38);

// Primary
$primary-main: #1A45D7;
$primary-contrastText: #FFFFFF;
$primary-4p: rgba($primary-main, 0.04);
$primary-8p: rgba($primary-main, 0.08);
$primary-12p: rgba($primary-main, 0.12);
$primary-30p: rgba($primary-main, 0.3);

// Success
$success-main: #419e3c;
$success-4p: rgba($success-main, 0.04);
$success-8p: rgba($success-main, 0.08);
$success-12p: rgba($success-main, 0.12);
$success-30p: rgba($success-main, 0.3);
$success-contrast: #ffffff;

// Info
$info-main: #0098db;

// Warning
$warning-main: #F15E17;
$warning-contrast: #FFFFFF;

// Error
$error-main: #DC2732;
$error-contrast: #FFFFFF;

// Background
$background-paper: #FAFAFA;
$background-paper2: #F3FCFF;

// Action
$action-disabled: rgba(#000000, 0.26);

// Other
$divider: rgba(#000000, 0.12);
$outline-border: rgba(#000000, 0.23);

// No equivalant in silica
$color-surface: #e1f5fc;
$color-disabled: #e0e0e0;
$color-on-primary: #202020;
$color-on-surface: #4e4e50;
$color-on-disabled: #9e9e9e;
$color-light-text: #4e4e50e5; 

// Elevations
$elevation-2: 0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033;
$elevation-4: 0px 1px 10px 0px #0000001F, 0px 4px 5px 0px #00000024, 0px 2px 4px -1px #00000033;

// Utilities
$card-border-radius: 4px;

// Fonts
$font-family: "Inter", Helvetica, Arial, sans-serif;