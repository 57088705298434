@import "./../../../../variables.scss";

.blength-custom-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;

    .blength-custom-title {
        margin-bottom: 0px;
    }

    .custom-b-length-field {
        margin-top: 16px;
    }
}
