@import "../../variables.scss";

.more-menu-container {
    position: absolute;
    top: 72px;
    right: 4px;
    width: 200px;
    background-color: $background-paper2;
    z-index: 2;
    box-shadow: $elevation-4;
    border-radius: $card-border-radius;

    ul {
        padding: 0;

        .menu-item {
            list-style-type: none;
            display: flex;
            padding: 8px 0px 8px 16px;
            cursor: pointer;
            align-items: center;
            color: rgba($color-on-surface, 0.85);

            &:hover {
                background-color: rgba($color-on-surface, 0.14);
            }

            &:active {
                background-color: $primary-8p;
            }

            .menu-item-icon {
                display: flex;
                align-items: center;
                margin-right: 8px;
                color: $primary-main;
            }

            .MuiTypography-root {
                color: $primary-main !important;
            }
        }
    }
}
