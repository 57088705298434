@import "../../variables.scss";
@import "../../styles.scss";

.modal-drawer {
    width: 876px;

    .rows-container {
        overflow-x: hidden;
        overflow-y: auto;
    }

    .scroll {
        margin-top: 5px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        color: $primary-main;
        font-family: "Inter";
    }

    .circular-progress-container {
        margin-top: 5px;
        display: flex;
        justify-content: center;

        .MuiCircularProgress-colorPrimary {
            color: $primary-main;
        }
    }
}
