@import "../../../styles.scss";

.edit-tolerances-dialog {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    position: absolute;
    top: $dialog-top-position;
    right: 16px;
    left: unset;
    width: 345px;

    @include card;

    .dialog-header {
        .dialog-header-title {
            color: $color-light-text;
        }
    }

    .action-container {
        display: flex;
        flex-direction: column;
        padding: 8px 16px 16px;

        .toggle-tolerances {
            display: flex;
            flex-direction: column;
            padding-bottom: 16px;

            .MuiFormHelperText-root {
                margin: 0;
                align-self: flex-end;
            }

            .MuiTypography-root {
                font-weight: bold;
            }
        }

        .actions {
            display: flex;
            justify-content: flex-end;

            .MuiButtonBase-root + .MuiButtonBase-root {
                margin-left: 8px !important;
            }
        }
    }
}
