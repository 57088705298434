@import "../../styles.scss";
@import "../../variables.scss";

$card-width: 1000px;
$componentsWidth: 25px;
$versionWidth: 25px;
$disclaimerWidth: 50px;

.third-party-copyright {
    &-container {
        overflow: none;
    }

    &-content {
        overflow: auto;
    }

    .MuiPaper-root {
        width: $card-width;
        max-width: $card-width;
    }

    .MuiTableCell-head {
        font-weight: bold;
    }

    .component-name-font {
        color: $info-main;
    }

    .dialog-header {
        width: 100%;
    }
}
