@import "../../styles.scss";
@import "../../variables.scss";

.about-this-application {
    .content-container {
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        margin-right: 0px;

        .left-container {
            padding-right: 10px;
        }

        .right-container {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            .right-container-title {
                margin-bottom: 0px;
            }

            .right-container-button {
                padding-left: 0px;
                padding-right: 0px;
                width: 100%;
            }
        }
    }

    .corning-copyright-container {
        display: flex;
        justify-content: center;

        .copyright-font {
            font-size: 12px;
        }
    }

    .dialog-header {
        width: 100%;
    }
}
