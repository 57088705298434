@import "../../variables.scss";

$card-width: 464px;

.propagation-dialog {
    .MuiPaper-root {
        width: $card-width;
        max-width: $card-width;
    }

    .MuiDialogContent-root {
        display: flex;
        flex-direction: column;
        padding-top: 0px;
        row-gap: 0px;

        .MuiFormControlLabel-root:nth-child(2) {
            margin-top: 8px;
        }
    }
}
