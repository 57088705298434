.drag-button {
    height: 48px;
    width: 48px;
    vertical-align: middle;
    opacity: 0.3;
}

.drawer-row {
    .drag-button {
        .lock-icon {
            content: url("../../../../resources/svgs/overlay/project-drawer/icon_lock.svg");
        }

        .rab-icon {
            content: url("../../../../resources/svgs/overlay/project-drawer/icon_eds.svg");
        }
    }

    &:hover {
        .drag-button {
            .lock-icon,
            .rab-icon {
                content: url("../../../../resources/svgs/overlay/project-drawer/icon_cloud.svg");
            }
        }
    }
}
