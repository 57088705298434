@import "../../variables.scss";
@import "../../styles.scss";

.workspace-header {
    user-select: none;
    display: flex;
    position: absolute;
    z-index: 1;
    top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    .header-start {
        display: flex;
        flex-direction: row;

        .lock-icon {
            padding-right: 0px;
        }
    }

    .header-end {
        display: flex;
        flex-direction: row;
    }

    .button-container {
        pointer-events: auto;
        user-select: none;
        border-radius: 4px;
        box-shadow: $elevation-2;

        .vertical-divider {
            width: 2px;
            height: 16px;
            background-color: rgba($color-on-surface, 0.2);
            margin-left: 8px;
            margin-right: 8px;
        }

        > button {
            height: 100%;
            text-transform: uppercase;
        }

        &.current-project {
            display: flex;
            align-items: center;
            background-color: $background-paper2;
            padding-right: 16px;
            padding-left: 8px;
            height: 48px;

            label {
                margin-bottom: 0;
                margin-left: 0;
                margin-right: 16px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-family: "Inter";
                text-transform: none;
            }

            .title {
                font-size: 16px;
                margin-right: 0;
                color: $color-light-text;
            }

            .sub-title {
                font-family: "Inter";
                font-size: 12px;
                color: $primary-main;
                height: 16px;
                margin-left: 8px;
                margin-right: 0;
            }

            .rab-title {
                font-family: "Inter";
                font-size: 20px;
                color: $primary-main;
                margin-left: 8px;
                margin-right: 0px;
            }

            .tool-item-container {
                .tool-item {
                    > span {
                        > button {
                            &:disabled {
                                > img {
                                    opacity: 0.2;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.request-quote,
        &.polarity {
            margin-right: 12px;
        }

        &.tools-container {
            display: flex;
            align-items: center;
            background-color: $background-paper2;
            padding-left: 8px;
            padding-right: 8px;
            height: 48px;

            i,
            button {
                color: $primary-main;
            }

            button:disabled {
                opacity: 0.4;
            }

            .vertical-divider {
                margin-left: 4px;
                margin-right: 4px;
            }
        }

        .MuiBadge-badge {
            font-size: 10px;
            height: 16px;
            max-width: 16px;
            left: 12px !important;
            top: 12px !important;
        }
    }

    .background-blur {
        @include blur(30px, $color-surface, true);
    }

    .tools-container {
        .warning-icon {
            margin-top: 6px !important;
        }

        .badge {
            position: absolute;
            min-width: 16px;
            height: 16px;
            bottom: 0;
            right: 100%;
            left: 52%;
            top: 52%;
            z-index: 1;
            border-radius: 8px;
            animation: pulse 1.5s 1;

            &.warning {
                color: $warning-contrast;
                background-color: $warning-main;
            }

            &.error {
                color: $error-contrast;
                background-color: $error-main;
            }

            &-number-container {
                display: flex;
                width: 16px;
                height: 16px;
                flex-direction: column;
                justify-content: center;

                .badge-number {
                    font-size: 10px;
                    animation: pulse 1.5s 1;
                    font-family: "Inter" !important;
                    width: 100%;
                    text-align: center;
                }
            }

            &::after {
                content: " ";
                position: absolute;
                right: 0px;
                bottom: 0px;
                top: -2px;
                left: -2px;
                border: 2px solid rgba($warning-main, 0.5);
                opacity: 0;
                border-radius: 8px;
                min-width: 16px;
                width: 100%;
                height: 16px;
                animation: sonar 1.5s 1;
            }
        }
    }
}

@keyframes sonar {
    0% {
        transform: scale(0.9);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.4);
    }
    50% {
        transform: scale(0.9);
    }
    80% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
