@import "../../../styles.scss";

.label-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 40px;
    width: 100%;

    background-color: white;
    border: 1px solid #101010;

    .label {
        padding: 8px 8px;
    }
}
