#app-status {
    display: flex;
    flex-direction: row;
    align-self: center;
    margin: 0px 24px;

    #status-locked {
        margin-left: 16px;
    }

    .status {
        display: flex;
        border-radius: 16px;
        opacity: 0;
        min-width: 120px;

        .status-icon {
            display: flex;
            flex-direction: row;
            margin: 8px 4px 8px 8px;
            .MuiSvgIcon-root {
                margin: 0px;
            }
        }

        .status-label {
            display: flex;
            flex-grow: 1;
            align-items: center;
            margin: 8px 8px 8px 4px;
        }

        &.fadeout {
            animation-duration: 2s;
            animation-name: fadeout;
        }

        @keyframes fadeout {
            from {
                opacity: 1;
            }

            to {
                opacity: 0;
            }
        }

        &.fadeloop {
            animation-duration: 1.5s;
            animation-name: fadeloop;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }

        @keyframes fadeloop {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }
    }
}
