@import "../../variables.scss";
@import "../../styles.scss";

.modal-drawer {
    top: 0;
    width: 650px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: $background-paper2;

    .children-container {
        display: contents;
    }
}
