@import "../../variables.scss";
@import "../../styles.scss";

$label-color: #191919;

.cable-details {
    .details-section {
        .details-section-label {
            padding-left: 16px;
            padding-bottom: 8px;
            font-family: "Inter", Arial;
            font-size: 16px;
            color: $color-on-primary;
        }

        .details-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 16px;
            padding-top: 8px;
            padding-bottom: 20px;

            .details-row {
                display: flex;
                flex-direction: row;
                padding-bottom: 20px;

                .details-column {
                    width: 33%;
                    display: flex;
                    flex-direction: column;
                    font-family: "Inter", Arial;

                    .details-label {
                        font-size: 12px;
                        color: $label-color;
                        opacity: 0.6;
                    }

                    .details-value {
                        font-size: 16px;
                        color: $color-on-surface;
                    }
                }
            }
        }
    }

    .details-buttons {
        padding-left: 16px;
        padding-right: 16px;

        .load-button {
            float: right;
        }
    }
}
