@import "../../../styles.scss";

.fields-section {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 16px;

    > .field-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .label-title {
        margin-bottom: 8px;
    }

    .multiline {
        height: 100% !important;
    }

    .field {
        height: 100% !important;
    }

    .max-length {
        margin-top: -20px !important;
    }


}
