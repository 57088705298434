@import "../../variables.scss";

.wizard-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 16px;
    -webkit-user-select: none; // Safari
    -moz-user-select: none; // Firefox
    -ms-user-select: none; // IE10+/Edge
    user-select: none; // Standard

    .wizard-header-title-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .wizard-header-title {
            padding-bottom: 3px;
        }

        .wizard-header-subtitle {
            height: 32px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    i {
        color: $primary-main;
    }
}
