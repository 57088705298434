@import "../variables.scss";

.snackbar-actions {
    display: flex;
    gap: 8px;

    .MuiButtonBase-root {
        color: white;
    }
}
