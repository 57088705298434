@import "../../styles.scss";

.warnings-card {
    position: absolute;
    top: $dialog-top-position;
    right: 16px;

    @include card;
    width: 408px;
    max-width: 408px;

    .filter-container {
        margin-left: 24px;
        margin-bottom: 16px;
        display: flex;
    }

    .warnings-list {
        list-style-type: none;
        padding: 0;
        max-height: 600px;
        overflow-y: scroll;

        .warnings-icon-cell {
            vertical-align: top;
        }
    }

    .warning-item {
        font-size: 16px;
        padding: 12px 0;
        border-top: 1px solid rgba($color-on-primary, 0.2);
        margin: 0 24px;

        &.error {
            .material-icons,
            .component-name,
            .problem-text {
                color: $error-main;
            }
        }

        &.warning {
            .material-icons,
            .component-name,
            .problem-text {
                color: $warning-main;
            }
        }

        .material-icons {
            vertical-align: middle;
            margin-right: 8px;
        }

        .component-name {
            font: "Inter" bold;
            line-height: 24px;
        }

        .problem-text {
            font-family: "Inter";
            line-height: 24px;
        }

        .solution-text {
            font-family: "Inter";
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.warnings-card-collapsed {
    position: absolute;
    top: $dialog-top-position;
    right: 16px;
    @include card;
    
    .dialog-header {
        gap: 8px;
    }
}
