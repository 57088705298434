@import "../../styles.scss";

.generic-dialog-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .generic-dialog-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .generic-dialog-title {
            font-size: 18px;
            font-family: "Inter";
            color: #4e4e50;

            &.critical {
                font-weight: bold;
            }
        }
    }

    .generic-dialog-body {
        padding: 16px 0px;
    }

    .generic-dialog-footer {
        width: 100%;
        .generic-dialog-button-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;

            .generic-dialog-cancel-button {
                margin-left: auto;
            }
        }
    }
}
