@import "../../../styles.scss";

.connector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    svg {
        &.mmc_connector {
            margin-right: 6px;
        }
    }

    button {
        border: 1px solid $primary-main !important;

        .material-icons {
            color: $primary-main;
        }

        &:disabled {
            border: 1px solid rgba($primary-main, 0.4) !important;

            .material-icons {
                color: rgba($primary-main, 0.4) !important;
            }
        }
    }
}
