@import "../../variables.scss";
@import "../../styles.scss";

.workspace-footer {
    pointer-events: none;
    display: flex;
    position: absolute;
    z-index: 1;
    bottom: 16px;
    width: 100%;
    height: 48px;
    justify-content: center;

    .controls-container {
        pointer-events: auto;
        user-select: none;
        font-size: 14px;
        font-family: "Inter", Arial;
        border-radius: 4px;
        background-color: $background-paper2;
        display: flex;
        align-items: center;

        .tool-vertical-divider {
            width: 2px;
            height: 16px;
            margin-left: 4px;
            margin-right: 4px;
            background-color: rgba($color-on-surface, 0.2);
        }
        height: 100%;

        &.zoom-container {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            left: 16px;
            width: 192px;
            box-shadow: $elevation-2;

            .zoom-actions {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 0 8px;

                .MuiIconButton-root + .MuiIconButton-root {
                    margin-left: 4px;
                }
            }

            .MuiTypography-root {
                display: flex;
                justify-content: center;
                width: 100%;
            }
        }

        i {
            color: $primary-main;
        }

        &.page-resize-container {
            position: absolute;
            width: 193px;
            max-width: 193px;
            min-width: 193px;
            left: 221px;
            justify-content: space-between;
            padding-left: 8px;
            padding-right: 8px;
            height: 48px;
            box-shadow: $elevation-2;

            .MuiIconButton-root {
                margin-left: 6px;
            }

            .page-resize-button {
                width: 130px;
                max-width: 130px;
                min-width: 130px;
                margin-left: 6px;
                font-size: 14px !important;
                font-family: "Inter", Arial !important;
                font-weight: normal !important;
                text-transform: none !important;
                justify-content: space-evenly;
                padding-bottom: 6px;
                color: black;

                &:hover {
                    background-color: $primary-4p;
                }
            }
        }

        &.current-units-container {
            position: absolute;
            left: 436px;
            background-color: transparent;

            .unit-label {
                margin-top: 1rem;
                margin-bottom: 1rem;
                margin-left: 8px;
                color: "#000000";
                font-size: 14px;
                font-family: "Inter", Arial;
            }
        }

        &.toolbar-container {
            padding-left: 8px;
            padding-right: 8px;
            height: 48px;
            box-shadow: $elevation-2;
        }
    }

    .tool-vertical-divider {
        width: 2px;
        height: 16px;
        background-color: rgba($color-on-surface, 0.2);
    }

    .background-blur {
        @include blur(30px, $color-surface);
    }

    .representative-example {
        position: absolute;
        right: 16px;
        bottom: 0;
        background-color: $background-paper2;
        border-radius: 4px;
        font: normal normal 400 12px "Inter";
        color: #101010;
        padding: 4px 12px;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        box-shadow: $elevation-2;
    }
}

.MuiMenu-list {
    border: 1px solid $info-main;
    border-radius: 4px 0 0 4px;
    background-color: $background-paper;
    width: 133px;
    height: 124px;
    font-size: 14px;
    font-family: "Inter", Arial;

    .MuiListItem-root {
        height: 36px;
        padding: 0 0 0 7px;
        font-size: 14px;
        font-family: "Inter", Arial;

        &:hover {
            color: rgba($background-paper, 0.9);
            background-color: $info-main;
        }

        &.Mui-selected {
            background-color: rgba($primary-4p, 0.4);

            &:hover {
                color: rgba($background-paper, 0.9);
                background-color: $info-main;
            }
        }
    }
}
