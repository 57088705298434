.build-info {
    display: flex;
    flex-direction: column;

    .build-part-number {
        padding-bottom: 8px;
    }

    .build-description {
        word-wrap: break-word;
        white-space: pre-wrap;
    }

    .build-polarity {
        white-space: nowrap;
    }
}
