@import "../../styles.scss";

$wizard-width: 390px;
$right-offset: 15px;
$padding: 16px 16px $right-offset 16px;
$top-offset: 80px;

.wizard {
    padding: $padding;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .setup {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding-top: 16px;

        .field-container {
            padding-bottom: 16px;

            &.horizontal {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &.no-padding {
                    padding-bottom: 0;
                }

                .generic-autocomplete-field-container {
                    width: 160px !important;
                    max-width: 170px !important;

                    .MuiFormControl-root {
                        .MuiFormLabel-root {
                            width: 200px !important;
                        }
                    }
                }

                .field {
                    width: 175px !important;
                    max-width: 185px !important;
                }

                .connector-separator {
                    margin: 16px 10px 0px 10px;
                    font-family: "Inter";
                }

                .ap-checkbox-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 170px;

                    .MuiFormControlLabel-root {
                        margin-left: 9px;
                        margin-right: 0px;
                    }

                    .ap-checkbox-label {
                        user-select: none;
                        cursor: pointer;
                        font-family: "Inter";

                        &.disabled {
                            user-select: none;
                            cursor: default;
                            color: $color-disabled;
                        }

                        &.checked {
                            user-select: none;
                            cursor: pointer;
                            color: $primary-main;
                        }
                    }
                }
            }

            &.nb-connectors {
                padding-bottom: 0px;
            }

            .reverse-staggering {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                padding-bottom: 0px;

                .MuiFormControlLabel-root {
                    margin-right: 0px;
                }

                .apply-to-all {        
                    .MuiFormControlLabel-root {
                        width: 148px;
                    }
                }

                .reverse-staggering:last-child {
                    margin-left: auto;
                }
            }
        }


        .switch-container {
            padding-left: 2px;

            .MuiIconButton-root {
                &:hover {
                    background-color: transparent;
                }
            }

            .MuiTypography-body1 {
                font-family: "Inter";
            }
        }

        .MuiFormHelperText-root {
            margin: 0px;
            white-space: nowrap;
        }
    }
}

.MuiList-root {
    width: auto !important;
    height: auto !important;
    max-height: 50% !important;
    &.MuiMenu-list {
        border: none;
    }
}

.card-container {
    @include card;
    position: absolute;
    display: inline-block;
    top: $top-offset;
    z-index: 1;
    margin-right: $right-offset;
    right: 2px;
    height: min(86%, 1015px); // 1015px => height of wizard
    overflow-y: auto;
    scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $primary-main;
    border-radius: $card-border-radius;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $primary-main;
}
