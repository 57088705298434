@import "../../variables.scss";
@import "../../styles.scss";

.connector-report-dialog {
    position: absolute;
    top: $dialog-top-position;
    right: 16px;

    @include card;
    overflow-x: hidden;
    height: auto;
    padding-bottom: 18px;

    .dialog-header {
        padding-bottom: 0px;
    }

    .table-container {
        border-top: 1px solid rgba($color-on-primary, 0.4);
        border-bottom: 1px solid rgba($color-on-surface, 0.2);
        height: 444px;
        overflow-y: scroll;
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 8px;

        .table-header-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .tool-item {
                margin: 0;
            }
        }
    }

    .MuiTableCell-head {
        background-color: $background-paper2;
    }

    .connector-report-footer {
        margin-top: 8px;
        margin-left: 24px;
        margin-right: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .tool-item-container + .tool-item-container {
            margin-left: 4px;
        }
    }

    .MuiPaper-root {
        touch-action: none;

        .MuiDialogContent-root {
            overflow-y: none;
        }

        .MuiDialogContent-root:first-child {
            padding: 0;
        }

        .MuiDialogContent-root.connector-report-header {
            padding: 8px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 0;
            overflow-y: hidden;

            i {
                color: $primary-main;
            }

            .MuiDialogTitle-root {
                padding: 0;

                .MuiTypography-root.MuiTypography-h6 {
                    font-size: 24px;
                    font-family: "Inter", Arial, Helvetica, sans-serif;
                    color: rgba(black, 0.65);
                }
            }
        }
    }

    .MuiTableCell-root {
        border-bottom: 1px solid rgba($color-on-surface, 0.2);
    }

    .label-field {
        min-height: 56px;

        &:hover {
            .MuiInputAdornment-root {
                visibility: initial;
            }
        }
        .MuiInputAdornment-root {
            visibility: hidden;
        }
    }
    .MuiTableBody-root {
        .MuiTableRow-root:last-child {
            .MuiTableCell-root {
                border-bottom: none;
            }
        }
    }

    .MuiTableCell-root.MuiTableCell-head {
        padding: 8px 8px 0 8px;
        font-size: 14px;
        font-family: "Inter", Arial, Helvetica, sans-serif;
        color: rgba(black, 0.5);
    }

    .MuiTableCell-root.MuiTableCell-body {
        padding: 0 8px 0 8px;
        font-size: 14px;
        font-family: "Inter", Arial, Helvetica, sans-serif;
    }

    .MuiPaper-root.MuiDialog-paper {
        overflow-y: 0;
        width: fit-content;
        min-width: 900px;
        height: 560px;
        background: #e1f5fc99;
        @include blur(30px, #e1f5fc99);
    }

    .MuiLinearProgress-root {
        background-color: rgba($info-main, 0.6);

        .MuiLinearProgress-bar {
            background-color: $info-main;
        }
    }

    .selection-column {
        width: 72px;
    }

    .connector-column {
        width: 96px;
    }

    .group-column {
        width: 72px;
    }

    .connector-color-column {
        width: 144px;
    }

    .label-column {
        width: 10em;
    }

    .b-length-column {
        width: 88px;
    }
}

.connector-report-dialog-collapsed {
    position: absolute;
    top: $dialog-top-position;
    right: 16px;
    @include card;

    .dialog-header {
        gap: 8px;
    }
}
