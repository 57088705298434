@import "../variables.scss";

.navigation-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 36px;

    .MuiIconButton-root {
        margin: 0 4px;
    }

    .MuiButton-root {
        min-width: 36px;
        height: 36px !important;
        margin: 0 4px;
    }

    .threshold {
        .material-icons {
            color: $color-on-disabled !important;
        }
    }

    .selected {
        background-color: rgba($primary-main, 0.2) !important;
    }
}
