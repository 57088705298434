@import "../../styles.scss";

.polarity-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    height: 48px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    background-color: rgba($color-surface, 0.5);
    box-shadow: $elevation-2;
    @include blur(30px, $color-surface);

    .polarity-toolbar-button {
        padding: 8px 8px;
    }

    .progress-container {
        display: flex;
        flex-direction: column;

        .assigned-fibers {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            color: $color-on-primary;
            padding-bottom: 6px;
            gap: 8px;

            .title {
                text-transform: uppercase;
            }

            .percentage {
                font-weight: bold;
            }
        }

        .progress-bar {
            box-sizing: content-box;
            width: 100%;
            height: 8px;
            border-radius: 25px;
            background-color: $color-disabled;

            span {
                display: block;
                height: 100%;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                background-color: $success-main;
            }

            span[style*="width: 100%;"] {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }
    }

    .vertical-divider {
        width: 2px;
        height: 16px;
        margin-left: 4px;
        margin-right: 4px;
        background-color: rgba($color-on-surface, 0.2);
    }
}
