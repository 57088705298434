@import "../../../styles.scss";

.label-scheme-dialog {
    @include card;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .scheme-content {
        display: flex;
        flex-direction: row;
        height: 400px;
        justify-content: space-between;
        padding: 0 16px;

        .templates-container {
            display: flex;
            flex-direction: column;

            .templates-title {
                padding-top: 6px;
                margin-bottom: 12px;
            }

            .templates {
                overflow-y: auto;
            }
        }

        .examples-container {
            display: flex;
            flex-direction: column;
            margin-left: 12px;

            .MuiTabs-root {
                min-height: 36px;
                height: 36px;

                .MuiTab-root {
                    padding: unset;
                    min-height: 36px;
                    min-width: 135px;
                    max-width: 135px;
                    width: 135px;
                }
            }

            .examples-panel {
                display: flex;
                height: 100%;
                width: 300px;

                .labels {
                    margin: 4px 4px;
                    width: 100%;

                    .label-container + .label-container {
                        margin-top: 4px;
                    }
                }
            }
        }
    }

    .scheme-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 20px 16px;

        .MuiFormControlLabel-root {
            flex-grow: 1;
        }

        .button-column {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .button-container {    
                .MuiButton-root + .MuiButton-root {
                    margin-left: 8px !important;
                }
            }
        }

    }

    .MuiFormControlLabel-root {
        user-select: none;
    }
}
