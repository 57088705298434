@import "../../../styles.scss";

.customization {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    padding: 16px 16px;

    #customization-container {
        padding: 16px 16px;
    }

    .controls {
        flex-grow: 1;
    }

    .button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    #paste-container {
        #paste-area {
            max-height: 400px;
            margin-bottom: 8px;

            .MuiTextField-root {
                height: 280px;
                max-height: 280px;

                .MuiInputBase-root {
                    height: 100%;
                    max-height: 400px;
                    padding: 0;

                    textarea {
                        height: 100% !important;
                        overflow: auto !important;
                        max-height: 400px;
                        padding-top: 16px;
                        padding-left: 16px;
                    }
                }
            }
        }
    }
}
