@import "../../../styles.scss";

.duplicate-dialog {
    .dialog-container {
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 32px;
        width: 420px;
    }

    .dialog-button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-right: 12px;
        padding-bottom: 12px;
    }
}
