@import "../../../variables.scss";

.tolerance-container {
    display: flex;
    flex-direction: column;
    padding: 8px 16px 8px;

    &.overall-length {
        padding-top: 0px;
    }

    &.label-distance {
        padding-bottom: 8px;
    }

    .MuiTypography-root {
        padding-bottom: 16px;
    }

    .MuiInputBase-root {
        .MuiTypography-root {
            padding: 0;
        }
    }

    .field-container {
        display: flex;
        flex-direction: row;

        .MuiTypography-root {
            display: flex;
            align-items: center;

            padding-left: 8px;
            padding-right: 8px;
        }
    }
}
