@import "../../css/variables.scss";

.delete-boundaries-confirmation {
    .MuiPaper-root {
        width: 546px;
    }
}

.boundaries-settings {
    .MuiPaper-root {
        width: 100%;
        height: 455px;

        .MuiDialogContent-root {
            height: fit-content;
        }
    }

    .boundaries-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 16px;

        &.modifying {
            .inline-text {
                padding: 0px;
                .MuiFormControl-root {
                    padding-left: 10px;
                }
            }

            .MuiTypography-root {
                padding-left: 10px;
                align-self: center;
            }
        }
    }

    .boundaries-content {
        padding-left: 8px;
        padding-right: 8px;

        &.modifying {
            padding-right: 10px;
            padding-left: 10px;
        }

        .subtitle-container {
            padding-bottom: 16px;
        }
    }

    .boundaries-list {
        max-height: 235px !important;
        overflow-y: scroll;

        .MuiListItem-root:hover {
            background-color: #00529314;
        }

        .boundaries-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 56px;
            cursor: unset;

            .MuiTypography-root {
                align-self: center;
            }
        }
    }
}
