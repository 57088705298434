.generic-auto-complete {
    .units {
        position: absolute;
        bottom: 0%;
        right: 100%;
        left: 70%;
        top: 33%;
        color: #00000099;
    }

    .MuiSvgIcon-root {
        color: #0000008A;
        padding-right: 6px;
    }
}