@import "../../../styles.scss";

.input-dialog {
    z-index: 6000 !important;

    .dialog-container {
        align-items: center;
        width: 420px;
        padding-top: 8px;
    }

    .dialog-button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }

    .cancel-button {
        margin-right: 8px !important;
    }

    .dialog-header {
        width: 100%;
    }
}
