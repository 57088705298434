@import "../../variables.scss";
@import "../../styles.scss";

$padding-bottom: 16px;

.wizard-footer {
    overflow: hidden;
    padding-bottom: $padding-bottom;

    .back-button {
        float: left;
    }

    .next-button {
        float: right;
    }
}

.edit-mode {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
